.list {
  margin-bottom: 30px;
}

.item {
  margin-top: 30px;
  display: flex;

  &-info {
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    color: var(--primary-color);

    div {
      margin-bottom: 12px;
    }
  }

  img {
    // background: #ffffff;
    border: 3px solid var(--primary-color);
    margin-right: 30px;
  }
}
