:root {
  --main-bg-color: #dad7cd;
  --main-color: #3a5a40;
  --primary-color: #588157;
  --light-main-color: #a3b18a;
}

.App {
  font-family: monospace;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: var(--main-bg-color);
  height: auto;
  min-height: 100vh;
}
